<template>
  <!-- prettier-ignore -->
  <div>
    <basic-text-input
      v-model="date"
      v-bind="$attrs"
      inputmode="decimal"
      manual-validation
      type="text"
      :disabled="$props.disabled"
      :supporting-text="$props.supportingText"
      :mask="'##.##.####'"
      :label="$props.label"
      :v="$props.v"
      @blur="handleBlur"
    >
      <template #leading>
        <slot name="leading" />
      </template>

      <template #trailing>
        <slot name="trailing" />
      </template>
    </basic-text-input>
  </div>
</template>

<script setup>
import { watch, watchEffect } from 'vue'

import { DateTimeHelper } from '@/helpers'

import BasicTextInput from '@/components/Basic/TextInput'

import { DATE_FORMAT_DIN, DATE_FORMAT_ISO } from '@/config/constants'

// INIT
const date = defineModel('date', { type: String })
const model = defineModel({ type: [String, Number] })
defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },

  supportingText: {
    type: [String],
    default: '',
  },

  label: {
    type: String,
    required: true,
  },

  v: {
    type: Object,
    default: () => {},
  },
})

// METHODS
function handleBlur() {
  if (date.value?.length !== 10) {
    model.value = null
  }
}

// WATCHERS
watch(
  () => date.value,
  value => {
    if (value === null) {
      // model has been cleared in textinput, therefore clear the model here as well
      model.value = null
    }

    if (value?.length === 10) {
      try {
        model.value = DateTimeHelper.formatDate(value, DATE_FORMAT_ISO)
      } catch (e) {
        date.value = null
      }
      props.v.$validate()
    }
  }
)

watchEffect(() => {
  if (model.value) {
    date.value = DateTimeHelper.formatDate(model.value, DATE_FORMAT_DIN)
  }
})
</script>
